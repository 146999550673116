import { AssignmentLeaveTypeEnum, ConfigurationInterface, FeatureEnum, FeatureFlagEnum } from '@nutt/configuration';

export const configuration: Readonly<ConfigurationInterface> = {
  tenant: { brand: 'Morrenhof-Jansen', logoUrl: 'assets/logo-text.png' },

  // This list is alphabetically sorted by name and should be kept that way since there is
  // no sorting in the components this is being used.
  branches: [
    { name: 'CIUS', value: 'YST' },
    { name: 'Dalfsen', value: 'DAL' },
    { name: 'Diever', value: 'DIE' },
    { name: 'Enschede', value: 'ECH' },
    { name: 'Gramsbergen', value: 'GRA' },
    { name: 'Hoogeveen', value: 'HOO' },
    { name: 'IJsselmuiden', value: 'YSS' },
    { name: 'Modus', value: 'MOD' },
    { name: 'Pensionado’s', value: 'PEN' },
    { name: 'Service', value: 'SVC' },
    { name: 'Vriezenveen', value: 'VRI' },
    { name: 'Waterrijk', value: 'SRV' },
    { name: 'Zwolle', value: 'ZWO' },
  ],

  features: [
    // The form feature is never properly tested beyond its pilot phase, so it is disabled for now.
    { feature: FeatureEnum.Forms, isDisabled: true },
    { feature: FeatureEnum.Compensation, isDisabled: true },
    {
      feature: FeatureEnum.Employees,
      flags: {
        [FeatureFlagEnum.LinkedWithLoket]: true,
        [FeatureFlagEnum.ChildrenField]: true,
        [FeatureFlagEnum.LeaveBalance]: true,
      },
    },
    {
      feature: FeatureEnum.Planning,
      flags: { [FeatureFlagEnum.DefaultWholeDay]: true, [FeatureFlagEnum.CanTransferEmployees]: true },
      metadata: {
        assignableLeaveTypes: [
          AssignmentLeaveTypeEnum.School,
          AssignmentLeaveTypeEnum.Sick,
          AssignmentLeaveTypeEnum.Leave,
          AssignmentLeaveTypeEnum.Doctor,
          AssignmentLeaveTypeEnum.ParentLeave,
        ],
        defaultStartOfWorkDay: '07:30',
        defaultEndOfWorkDay: '16:15',
      },
    },
  ],
};
